<template>
  <div class="deposit-index">
    <subnav currPage="footerDeposit"></subnav>
    <v-container class="my-5">
      <div class="deposit-card px-4 px-sm-8 pt-3 pb-2">
        <div class="d-flex justify-space-between align-center mb-2">
          <div class="d-flex align-center">
            <img :src="`${require(`@/assets/img/icon-polygon-${$route.params.depositToken}-grey.svg`)}`" :alt="$route.params.depositToken" class="ml-n1 mr-2">
            <div class="font-share-tech font-w-4">
              <span class="rem-18 rem-sm-23 lightPrimary2--text mr-1">{{ $route.params.depositToken.toUpperCase() }}</span>
              <span class="rem-1 rem-sm-3 lightGrey1--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
            </div>
          </div>
          <div style="width: 100px; height: 21px">
            <v-select
              class="deposit-token-select"
              filled
              flat
              solo
              dense
              hide-details
              item-text="token"
              item-value="token"
              append-icon="mdi-chevron-down"
              :items="$store.state.depositTokens.map(item => item.token.toUpperCase())"
              :value="($route.params.depositToken).toUpperCase()"
              @change="selectDepositToken"
            ></v-select>
          </div>
        </div>

        <div class="title">{{ $t('depositAmount') }}</div>
        <v-form id="depositForm" ref="depositForm" lazy-validation>
          <v-text-field
            class="amount-input"
            v-model="amount"
            :placeholder="$t('enterDepositTokenAmount', {token: $route.params.depositToken.toUpperCase()})"
            filled
            flat
            solo
            clearable
            background-color="#04040466"
            height="46px"
            clear-icon="mdi-close-circle"
            :rules="[...TokenAmountRules, integerRule, (v) => v <= balance || 'Under Balance']"
            persistent-hint
            :hint="`${$t('tokenAvailable', {amount: (balance || 0).toLocaleString(), token: $route.params.depositToken.toUpperCase()})}`"
          >
            <template v-slot:append>
              <div class="lightPrimary2--text can-click">
                <div @click="amount = (balance || 0)">{{ $t('max') }}</div>
              </div>
            </template>
          </v-text-field>
        </v-form>

        <hr class="line" />

        <div class="d-flex justify-space-between align-center font-w-4">
          <span class="title">{{ $t('detailedInfo') }}</span>
          <span class="rem-2 lightGrey1--text">{{ $t('profitDescription', {token: $route.params.interestToken.toUpperCase()}) }}</span>
        </div>

        <div class="details-block pa-4 rem-3">
          <div class="d-flex justify-space-between align-center mb-3">
            <div>{{ $t('depositDate') }}</div> <span>{{ timestampToDate(newTimeInfo.start * 1000 || 0) }}</span>
          </div>
          <div class="d-flex justify-space-between align-center mb-3">
            <div>{{ $t('startInterestDate') }}</div> <span>{{ timestampToDate(newTimeInfo.valut_date * 1000 || 0) }}</span>
          </div>
          <div class="d-flex justify-space-between align-center">
            <div>{{ $t('distributeInterestDate') }}</div> <span>{{ timestampToDate(newTimeInfo.payment_date * 1000 || 0) }}</span>
          </div>
        </div>

        <hr class="line" />

        <div class="d-flex justify-space-between align-center font-w-4">
          <span class="title">{{ $t('monthExpectedRate') }}</span>
          <div class="can-click" @click="isShowAllRate = !isShowAllRate">
            <span class="rem-3 lightGrey1--text">{{ $t(isShowAllRate ? 'showLess' : 'showMore') }}</span>
            <v-icon color="lightGrey1" size="18px" class="mx-2" :style="`transform: rotate(${isShowAllRate ? '180deg' : '0'})`">mdi-chevron-down</v-icon>
          </div>
        </div>

        <div class="month-rate-block pa-4 rem-3 mb-5">
          <div class="d-flex justify-space-between align-center mb-3 lightPrimary2--text">
            <div>{{ $t('firstMonth') }}</div> <span>{{ (newRates[0] * 100).toFixed(2) }} %   {{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
          <div class="d-flex justify-space-between align-center mb-3 lightGrey1--text" v-for="(rate, i) in isShowAllRate ? newRates.slice(1) : newRates.slice(1, 4)" :key="i">
            <div>{{ $t('nthMonth', {month: i + 2}) }}</div> <span>{{ (newRates[i+1] * 100).toFixed(2) }} %   {{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
        </div>

        <v-checkbox color="lightGrey1" class="mb-6" hide-details v-model="isConfirm">
          <template v-slot:label>
            <div @click.stop>
              <div v-html="$t('agreeRule', {lang: $store.state.locale})"></div>
            </div>
          </template>
        </v-checkbox>

        <v-btn v-if="allowance===0 || allowance<balance" color="#C93F3F" width="100%" height="38" class="rem-6 mb-2" light depressed @click="clickBtn('approve')">{{ $t('approve') }}</v-btn>
        <v-btn v-else color="lightPrimary2" width="100%" height="38" class="rem-6 mb-2" light depressed @click="clickBtn('deposit')">{{ $t('depositImmediately') }}</v-btn>
        <div class="rem-3 font-w-4 text-center lightPrimary2--text">{{ $t('depositDescription') }}</div>
      </div>
    </v-container>
  </div>
</template>
<script>
import subnav from '@/components/subnav.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data () {
    return {
      isShowAllRate: false,
      newTimeInfo: {},
      newRates: [],
      amount: '',
      balance: 0,
      allowance: 0,
      timer: null,
      isConfirm: false,
    }
  },
  components: {
    subnav,
  },
  watch: {
    "$route.params.depositToken": {
      handler: async function() {
        this.balance = 0
        this.allowance = 0
        await this.getDepositBalance()
        await this.getDepositAllowance()
      }
    }
  },
  methods: {
    selectDepositToken(token) {
      this.$router.push({name: 'Deposit', params: {lang: this.$store.state.locale, interestToken: this.$route.params.interestToken, depositToken: token.toLowerCase()}})
    },
    async getDepositBalance() {
      this.balance = await this.$token[this.$route.params.depositToken].getBalance()
    },
    async getDepositAllowance() {
      this.allowance = await this.$token[this.$route.params.depositToken].getAllowance(this.$store.state.vaultAddress)
    },
    clickBtn(type) {
      if (this.isConfirm) {
        if (this.$refs.depositForm.validate()) {
          if (type === 'approve') {
            this.approve()
          } else {
            this.deposit()
          }
        } else {
          this.$vuetify.goTo('#depositForm')
        }
      } else {
        this.$toasted.error(this.$t('agreeFirst'))
      }
    },
    async approve() {
      if (this.$store.state.chainId){
        let result = await this.$token[this.$route.params.depositToken].approve(this.$store.state.vaultAddress)
        // console.log('result', result)
        if (result.txHash){
          this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitApprove')})
          this.timer = window.setInterval(async () => {
            await this.getDepositAllowance()
            if (this.allowance >= this.balance) {
              window.clearInterval(this.timer)
              this.$store.commit('updateLoading', {isShow: false, text: ''})
            }
          }, 1000)
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async deposit() {
      if (this.$store.state.chainId){
        let result = await this.$vault.deposit(this.$token[this.$route.params.depositToken].tokenAddress, this.$token[this.$route.params.interestToken].tokenAddress, this.amount)
        // console.log('result', result)
        if (result.txHash){
          this.$toasted.show(this.$t('txSend'))
          this.$refs.depositForm.reset()
          this.isConfirm = false
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    }
  },
  async created() {
    let result = await this.$store.dispatch('getNewOrderDate')
    if (result.status === 200 && result.data) {
      this.newTimeInfo = result.data.new_order_detial
      this.newRates = result.data.new_order_month['1']
    }

    await this.getDepositBalance()
    await this.getDepositAllowance()
  }
}
</script>
<style lang="scss">
.deposit-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
  }
  .deposit-card {
    background: linear-gradient(93.36deg, rgba(99, 99, 99, 0.8) 1.1%, rgba(54, 54, 54, 0.8) 96.27%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3px);
    border-radius: 5px;
    @include dai_vuetify_sm {
      border: 1px solid #5D5D5D;
    }
  }

  .title {
    color: var(--v-lightPrimary2-base);
    font-size: 18px;
    margin-bottom: 7px;
    @include dai_vuetify_sm {
      font-size: 15px;
    }
  }

  .amount-input .v-icon::before {
    font-size: 12px;
    color: white;
  }

  .deposit-token-select {
    .v-input__control, .v-input__slot {
      min-height: 21px !important;
    }
    .v-input__slot {
      background: linear-gradient(103deg, #636363 4%, #313131 101%) !important;
    }
  }

  .details-block, .month-rate-block {
    background: rgba(4, 4, 4, 0.4);
    border-radius: 2px;
    div {
      position: relative;
      div:first-child::before {
        position: relative;
        display: inline-block;
        content: '';
        width: 7.5px;
        height: 7.5px;
        margin-right: 8px;
        background: var(--v-lightPrimary2-base);
        border-radius: 1px;
        transform: rotate(45deg);
        z-index: 2;
      }
      &:nth-child(n+2)div:first-child::after {
        position: absolute;
        content: '';
        left: 3.3px;
        top: calc(-50% - 8px);
        background: var(--v-lightGrey1-base);
        width: 1px;
        height: 125%;
        z-index: 1;
      }
    }
  }

  .details-block div:nth-last-child(1)div:first-child::after {
    background: #53A29C; 
  }

  .month-rate-block div:nth-child(n+2) div:first-child::before {
    background: var(--v-lightGrey1-base);
  }

  .line {
    color: #63636380;
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin: 10px 0;
  }
}
</style>